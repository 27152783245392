<script setup lang="ts">
	import { ref } from "vue";
	import { useI18n } from "vue-i18n";
	import Button from "primevue/button";
	const scrollToBookSection = () => {
		if (document !== null) {
			document
				?.getElementById("book-section")
				?.scrollIntoView({ behavior: "smooth" });
		}
	};

	const { locale } = useI18n();

	const { data: homeResponse } = await useLarafetch<{
		daily_price: number;
	}>(`/api/v1/${locale.value}/public/home`);
</script>

<template>
	<div class="grid grid-nogutter surface-section">
		<div
			class="col-12 md:col-6 p-6 text-center md:text-left flex align-items-center"
		>
			<section>
				<span class="block text-4xl font-bold mb-1 text-800">
					{{ $t("home.landing.hero") }}
					<span class="text-primary subtitle-underline">
						{{ $t("home.landing.subtitle") }}
					</span>
				</span>

				<span class="flex flex-column text-left gap-4 mt-6">
					<span class="flex align-items-start">
						<i class="text-primary text-2xl pi pi-check-circle mr-2 h-16"></i>

						<span>
							{{ $t("home.landing.list.redScooters") }}
						</span>
					</span>
					<span class="flex align-items-start">
						<i class="text-primary text-2xl pi pi-check-circle mr-2 h-16"></i>

						<span>
							{{ $t("home.landing.list.goodService") }}
						</span>
					</span>
					<span class="flex align-items-start">
						<i class="text-primary text-2xl pi pi-check-circle mr-2 h-16"></i>
						<span>
							{{ $t("home.landing.list.goodPrices") }}
						</span>
					</span>
					<span class="flex align-items-start">
						<i class="text-primary text-2xl pi pi-check-circle mr-2 h-16"></i>

						<span>
							{{ $t("home.landing.list.freePark") }}
						</span>
					</span>
				</span>
				<div
					class="my-6 flex justify-content-center flex flex-column gap-4 align-items-center"
				>
					<div>
						<Button
							:label="$t('home.landing.checkAvailability')"
							icon="pi pi-search"
							size="large"
							@click="scrollToBookSection"
						/>
					</div>
					<div class="text-600 text-xl">
						{{ $t("Price per 1 Day") }}
						<strong>{{ homeResponse?.daily_price ?? 65 }}€</strong>
					</div>
				</div>
			</section>
		</div>
		<div class="col-12 md:col-6 overflow-hidden img-container">
			<nuxt-img
				src="/images/landing.jpg"
				alt="Landing Image"
				class="md:ml-auto block md:h-full"
				layout="responsive"
				width="739"
				height="800"
				format="webp"
				quality="80"
				priority
				sizes="30vw sm:25vw md:50vw"
			/>
		</div>
	</div>
</template>

<style scoped>
	.img-container img {
		max-height: 800px;
	}
	@media (min-width: 768px) {
		.img-container img {
			clip-path: polygon(6% 0, 100% 0%, 100% 100%, 0 100%);
		}
	}
	.subtitle-underline {
		position: relative;
	}

	.subtitle-underline::after {
		content: "";
		position: absolute;
		left: 0;
		right: 0;
		bottom: -4px; /* Offset per lo spazio sotto la sottolineatura */
		height: 3px; /* Spessore della sottolineatura */
		background-color: #22383d; /* Colore della sottolineatura */
		border-radius: 50px;
	}
</style>
