<script setup lang="ts">
	import type { Branch, JsonResource } from "~/types/app";
	import Landing from "~/components/Page/Home/Landing.vue";
	import BookSection from "~/components/Page/Home/BookSection.vue";
	import Faq from "~/components/Page/Home/Faq.vue";
	import { useI18n } from "vue-i18n";
	import { useRoute } from "vue-router";

	const { t } = useI18n();
	useSeoMeta({
		title: "Vispetta - Home",
		ogTitle: t("ourMotorcycles.experience.list.first.title"),
		description: t("ourMotorcycles.experience.list.first.description"),
		ogDescription: t("ourMotorcycles.experience.list.first.description"),
		ogImage: "/images/landing.jpg",
		twitterCard: "summary_large_image",
	});
	const route = useRoute();

	const withParam = route?.query.with as string;

	const { locale } = useI18n();
	const { data: branches, error } = await useLarafetch<JsonResource<Branch[]>>(
		`/api/v1/${locale.value}/public/branch`,
		{
			params: {
				with: withParam,
			},
		}
	);

	
</script>

<template>
	<Landing />
	<BookSection v-if="branches !== null" :branches="branches" />
	<iframe
		title="Vispetta Google Maps"
		src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2796.3605294216622!2d10.741397276480772!3d45.502820571074544!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4781eff578209529%3A0xcf438c0dd5a51c4f!2sVespa%20Scooter%20Rental%20-%20Vispetta%20%F0%9F%9B%B5!5e0!3m2!1sen!2sit!4v1686302468348!5m2!1sen!2sit&layer=c"
		width="100%"
		height="500"
		style="border: 0"
		loading="lazy"
		referrerpolicy="no-referrer-when-downgrade"
	></iframe>
	<Faq />
</template>
